const servers =  [
    {
        path: '/servers/list',
        name: 'servers.list',
        component: () => import('@/pages/Server/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Servers",
        }
    },
    {
        path: '/servers/create',
        name: 'servers.create',
        component: () => import('@/pages/Server/CreatePage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Server create",
        }
    },
    {
        path: '/servers/show/:server',
        name: 'servers.show',
        component: () => import('@/pages/Server/ShowPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Server view",
        }
    },
    {
        path: '/servers/edit/:server',
        name: 'servers.edit',
        component: () => import('@/pages/Server/EditPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Server edit",
        }
    },
    {
        path: '/servers/cpu',
        name: 'servers.cpu',
        component: () => import('@/pages/Server/ServerConfiguration/CPU/IndexCPU.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "CPU",
        }
    },
    {
        path: '/servers/cpu/create',
        name: 'servers.cpuCreate',
        component: () => import('@/pages/Server/ServerConfiguration/CPU/CreateCPU.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "CPU create",
        }
    },
    {
        path: '/servers/cpu/show/:cpuID',
        name: 'servers.cpuShow',
        component: () => import('@/pages/Server/ServerConfiguration/CPU/ShowCPU.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "CPU show",
        }
    },
    {
        path: '/servers/cpu/edit/:cpuID',
        name: 'servers.cpuEdit',
        component: () => import('@/pages/Server/ServerConfiguration/CPU/CreateCPU.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "CPU edit",
        }
    },
    {
        path: '/servers/ram',
        name: 'servers.ram',
        component: () => import('@/pages/Server/ServerConfiguration/RAM/IndexRAM.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "RAM",
        }
    },
    {
        path: '/servers/ram/create',
        name: 'servers.ramCreate',
        component: () => import('@/pages/Server/ServerConfiguration/RAM/CreateRAM.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "RAM create",
        }
    },
    {
        path: '/servers/ram/show/:ramID',
        name: 'servers.ramShow',
        component: () => import('@/pages/Server/ServerConfiguration/RAM/ShowRAM.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "RAM show",
        }
    },
    {
        path: '/servers/ram/edit/:ramID',
        name: 'servers.ramEdit',
        component: () => import('@/pages/Server/ServerConfiguration/RAM/CreateRAM.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "RAM edit",
        }
    },
    {
        path: '/servers/disk',
        name: 'servers.disk',
        component: () => import('@/pages/Server/ServerConfiguration/Disk/IndexDisk.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Disk",
        }
    },
    {
        path: '/servers/disk/create',
        name: 'servers.diskCreate',
        component: () => import('@/pages/Server/ServerConfiguration/Disk/CreateDisk.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Disk create",
        }
    },
    {
        path: '/servers/disk/show/:diskID',
        name: 'servers.diskShow',
        component: () => import('@/pages/Server/ServerConfiguration/Disk/ShowDisk.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Disk show",
        }
    },
    {
        path: '/servers/disk/edit/:diskID',
        name: 'servers.diskEdit',
        component: () => import('@/pages/Server/ServerConfiguration/Disk/CreateDisk.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Disk edit",
        }
    },
    {
        path: '/servers/raid',
        name: 'servers.raid',
        component: () => import('@/pages/Server/ServerConfiguration/Raid/IndexRaid.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "HbaRaid",
        }
    },
    {
        path: '/servers/raid/create',
        name: 'servers.raidCreate',
        component: () => import('@/pages/Server/ServerConfiguration/Raid/CreateRaid.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "HbaRaid create",
        }
    },
    {
        path: '/servers/raid/show/:raidID',
        name: 'servers.raidShow',
        component: () => import('@/pages/Server/ServerConfiguration/Raid/ShowRaid.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "HbaRaid show",
        }
    },
    {
        path: '/servers/raid/edit/:raidID',
        name: 'servers.raidEdit',
        component: () => import('@/pages/Server/ServerConfiguration/Raid/CreateRaid.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "HbaRaid edit",
        }
    },
    {
        path: '/servers/network',
        name: 'servers.network',
        component: () => import('@/pages/Server/ServerConfiguration/Network/IndexNetwork.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Network",
        }
    },
    {
        path: '/servers/network/create',
        name: 'servers.networkCreate',
        component: () => import('@/pages/Server/ServerConfiguration/Network/CreateNetwork.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Network create",
        }
    },
    {
        path: '/servers/network/show/:networkID',
        name: 'servers.networkShow',
        component: () => import('@/pages/Server/ServerConfiguration/Network/ShowNetwork.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Network show",
        }
    },
    {
        path: '/servers/network/edit/:networkID',
        name: 'servers.networkEdit',
        component: () => import('@/pages/Server/ServerConfiguration/Network/CreateNetwork.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Network edit",
        }
    },
    {
        path: '/servers/gpu',
        name: 'servers.gpu',
        component: () => import('@/pages/Server/ServerConfiguration/GPU/IndexGPU.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "GPU",
        }
    },
    {
        path: '/servers/gpu/create',
        name: 'servers.gpuCreate',
        component: () => import('@/pages/Server/ServerConfiguration/GPU/CreateGPU.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "GPU create",
        }
    },
    {
        path: '/servers/gpu/show/:gpuID',
        name: 'servers.gpuShow',
        component: () => import('@/pages/Server/ServerConfiguration/GPU/ShowGPU.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "GPU show",
        }
    },
    {
        path: '/servers/gpu/edit/:gpuID',
        name: 'servers.gpuEdit',
        component: () => import('@/pages/Server/ServerConfiguration/GPU/CreateGPU.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "GPU edit",
        }
    },
    {
        path: '/servers/device',
        name: 'servers.device',
        component: () => import('@/pages/Server/ServerConfiguration/Device/IndexDevice.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Device",
        }
    },
    {
        path: '/servers/device/create',
        name: 'servers.deviceCreate',
        component: () => import('@/pages/Server/ServerConfiguration/Device/CreateDevice.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Device create",
        }
    },
    {
        path: '/servers/device/show/:deviceID',
        name: 'servers.deviceShow',
        component: () => import('@/pages/Server/ServerConfiguration/Device/ShowDevice.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Device show",
        }
    },
    {
        path: '/servers/device/edit/:deviceID',
        name: 'servers.deviceEdit',
        component: () => import('@/pages/Server/ServerConfiguration/Device/CreateDevice.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Device edit",
        }
    },
    {
        path: '/servers/board',
        name: 'servers.board',
        component: () => import('@/pages/Server/ServerConfiguration/Board/IndexBoard.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Motherboard",
        }
    },
    {
        path: '/servers/board/create',
        name: 'servers.boardCreate',
        component: () => import('@/pages/Server/ServerConfiguration/Board/CreateBoard.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Motherboard create",
        }
    },
    {
        path: '/servers/board/show/:boardID',
        name: 'servers.boardShow',
        component: () => import('@/pages/Server/ServerConfiguration/Board/ShowBoard.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Motherboard show",
        }
    },
    {
        path: '/servers/board/edit/:boardID',
        name: 'servers.boardEdit',
        component: () => import('@/pages/Server/ServerConfiguration/Board/CreateBoard.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Motherboard edit",
        }
    },
    {
        path: '/servers/frame',
        name: 'servers.frame',
        component: () => import('@/pages/Server/ServerConfiguration/Frame/IndexFrame.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Server case",
        }
    },
    {
        path: '/servers/frame/create',
        name: 'servers.frameCreate',
        component: () => import('@/pages/Server/ServerConfiguration/Frame/CreateFrame.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Server case create",
        }
    },
    {
        path: '/servers/frame/show/:frameID',
        name: 'servers.frameShow',
        component: () => import('@/pages/Server/ServerConfiguration/Frame/ShowFrame.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Server case show",
        }
    },
    {
        path: '/servers/frame/edit/:frameID',
        name: 'servers.frameEdit',
        component: () => import('@/pages/Server/ServerConfiguration/Frame/CreateFrame.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Server case edit",
        }
    },
    {
        path: '/servers/active',
        name: 'servers.active',
        component: () => import('@/pages/Server/ActiveServer/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Готовые серверы",
        }
    },
    {
        path: '/servers/active/create',
        name: 'servers.active.create',
        component: () => import('@/pages/Server/ActiveServer/CreatePage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Готовые серверы",
        }
    },
    {
        path: '/servers/active/show/:id',
        name: 'servers.active.show',
        component: () => import('@/pages/Server/ActiveServer/ShowPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Готовые серверы",
        }
    },
    {
        path: '/servers/active/edit/:id',
        name: 'servers.active.edit',
        component: () => import('@/pages/Server/ActiveServer/CreatePage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Готовые серверы",
        }
    },
]

export default servers